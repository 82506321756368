import {Button as AntdButton, Col as AntdCol, Form, Input, notification, Row as AntdRow, Select} from "antd";
import React, {useEffect, useState} from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useUser } from "../../hooks";
import User from "../../model/User";
import {useParams} from "react-router-dom";
import {episodesActivation, getEpisodeDetail, getStoryCharacters, getStoryDetail} from "../../helpers/api/story";
import PageTitle from "../PageTitle";
import ChatList from "../ChatList";
import {getModels} from "../../helpers/api/modelChat";
import SelectModelChat from "./SelectModel";


const EpisodeChat = () => {
    const { storyId } = useParams();
    const { episodeId } = useParams();

    const [story, setStory] = useState<any>(null);
    const [episode, setEpisode] = useState<any>(null);
    const [characters, setCharacters] = useState<any[]>([]);
    const [model, setModel] = useState<string>('');
    const [error, setError] = useState<string>('');

    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [listModelChat, setListModelChat] = useState<any[]>([]);

    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }


    useEffect(() => {
        if (!storyId) {
            return;
        }
        if (!episodeId) {
            return;
        }
        getListModelChat();
        getStoryDetailById();
        getCharacters();
        getEpisodeDetailById();
        activeEpisode();
    }, [storyId, episodeId]);

    const getStoryDetailById = async () => {
        try {
            const res = await getStoryDetail(storyId as string);
            setStory(res.data.data);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    const getCharacters = async () => {
        try {
            const response = await getStoryCharacters(storyId as string, { pageSize: 100, pageIndex: 0 });
            if (response.status === 200) {
                // const data = response.data.data.filter((item: any) => item?.status === "active");
                setCharacters(response.data.data);
            }
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    const getEpisodeDetailById = async () => {
        try {
            const res = await getEpisodeDetail(storyId as string, episodeId as string);
            setEpisode(res.data.data);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    const activeEpisode = async () => {
        try {
            await episodesActivation(storyId as string, episodeId as string);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }

    }

    const getListModelChat = async () => {
        try {
            const res = await getModels();
            setListModelChat(res.data.listModelChat)
        } catch (err) {
            console.error(err);
            showError(err as string);
        }

    }

    useEffect(() => {
        if (error) {
            showError(error);
            setError('')
        }
    }, [error]);




    return (
        <>
            {contextHolder}

            <PageTitle
                breadCrumbItems={[
                    { label: "Stories", path: "/stories", active: false },
                    { label: "Stories detail", path: "/stories/" + storyId, active: false },
                    { label: "Chat", path: "/stories/" + storyId + "/episodes/" + episodeId + "/chat", active: true },
                ]}
                title={<span><span className="text-danger">{episode?.name}</span> Chat</span>}
            />
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Form layout="vertical" autoComplete='off' form={form} name={"Config"}>
                                <AntdRow gutter={16}>
                                    <AntdCol span={12}>
                                        <Form.Item
                                            name="Model"
                                            label="Model"
                                        >
                                            <SelectModelChat models={listModelChat} handleModelChange={(value) => form.setFieldValue('Model', value)} />
                                        </Form.Item>
                                    </AntdCol>
                                </AntdRow>
                                <AntdRow className="justify-content-center">
                                    <AntdCol>
                                        <AntdButton type="primary" className="me-1" htmlType="submit" loading={searchLoading}
                                                    onClick={() => {
                                                        debugger;
                                                        form.validateFields().then(values => {
                                                            debugger;
                                                            setModel(values.Model);
                                                        }).catch(err => {
                                                            console.error(err);
                                                        });
                                                    }}
                                        >
                                            Apply
                                        </AntdButton>
                                    </AntdCol>
                                </AntdRow>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <ChatList episodeDetail={episode} characters={characters} setError={setError} model={model} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default EpisodeChat;
