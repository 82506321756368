import axios, { AxiosRequestConfig } from "axios";
import jwtDecode from "jwt-decode";

import config from "../../config";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

axios.interceptors.request.use(function (config: any) {
  let token = getTokenFromCookie();
  if (token) {
    const { accessToken } = token;
    if (accessToken) {
      config.headers.Authorization = "Bearer " + accessToken;
    }
  }

  return config;
});

// intercepting to capture errors
axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message =
      error.response && error.response.data
        ? error.response.data["messages"]
        : error.message || error;

    if (error && error.response && error.response.status === 404) {
      window.location.href = '/error-404';
    } else {
      switch (error.response.status) {
        case 401:
          message = message || "Invalid credentials";
          break;
        case 403:
          message = message || "Access Forbidden";
          break;
        case 404:
          message = message || "Not foud";
          break;
        // default: {
        //   message =
        //     error.response && error.response.data
        //       ? error.response.data["message"]
        //       : error.message || error;
        // }
      }
      return Promise.reject(message);
    }
  }
);

const AUTH_SESSION_KEY = "token";
const USER_INFO_KEY = "user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  // if (token) axios.defaults.headers.common["Authorization"] = "JWT " + token;
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  const user = sessionStorage.getItem(USER_INFO_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

const getTokenFromCookie = () => {
  const token = sessionStorage.getItem(AUTH_SESSION_KEY);
  return token ? (typeof token == "object" ? token : JSON.parse(token)) : null;
};

class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any) => {
    return axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      } as any,
    } as AxiosRequestConfig;
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  createWithFileInFormData = (url: string, formData: FormData) => {
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      } as any,
    } as AxiosRequestConfig;
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      } as any,
    } as AxiosRequestConfig;
    return axios.patch(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFilePut = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      } as any,
    } as AxiosRequestConfig;
    return axios.put(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInToken();

    if (!user) {
      return false;
    }
    const decoded: any = jwtDecode(user.accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  setLoggedInUser = (user: any) => {
    if (user) {
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(user.token));
      sessionStorage.setItem(USER_INFO_KEY, JSON.stringify(user.user));
    }
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
      sessionStorage.removeItem(USER_INFO_KEY);
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  getLoggedInToken = () => {
    return getTokenFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
// let token = getTokenFromCookie();
// if (token) {
//   const { accessToken } = token;
//   if (accessToken) {
//     setAuthorization(accessToken);
//   }
// }

export { APICore, setAuthorization };
