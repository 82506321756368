import React, {useEffect, useMemo, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import {Card, Dropdown, Row, Col} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import classNames from "classnames";

//components
import {FormInput} from "../components/";

import profilePic from "../assets/images/users/user-1.jpg";
import {ChatRequest, EvaluationModel, EvaluationModel2, MessageItem} from "../model/Chat";
import {APICore} from "../helpers/api/apiCore";
import {continuations} from "../helpers/api/conversation";
import {episodesEvaluation, episodesReset} from "../helpers/api/story";
import {Button, Modal} from "antd";

/* Chat Item Avatar */
const ChatItemAvatar = ({
                            userAvatar
                        }: {
    userAvatar: string;
}) => {
    return (
        <>
            <div className="chat-avatar">
                <img src={userAvatar} alt={userAvatar}/>
            </div>
        </>
    );
};

/* Chat Item Text */
const ChatItemText = ({
                          userName,
                          text,
                      }: {
    userName: string;
    text: string;
}) => {
    return (
        <>
            <div className="conversation-text">
                <div className="ctext-wrap">
                    <i>{userName}</i>
                    <p>{text}</p>
                </div>
            </div>
        </>
    );
};

/* Chat Item */
const chatItemDefaultProps = {
    placement: "",
    children: PropTypes.object,
    className: "",
};

const ChatItem = ({
                      children,
                      placement,
                      className,
                  }: {
    children: any;
    placement: string;
    className: string;
}) => {
    return (
        <li
            className={classNames(
                "clearfix",
                {odd: placement === "left"},
                className
            )}
        >
            {children}
        </li>
    );
};

ChatItem.defaultProps = chatItemDefaultProps;

/**
 * ChatForm
 */

interface FormValues {
    newMessage: string;
}

/**
 * Renders the ChatForm
 */
const ChatForm = ({
                      onNewMessagesPosted,
    disable
                  }: {
    onNewMessagesPosted: (message: string) => void;
    disable: boolean;
}) => {
    /*
     * form validation schema
     */
    const schemaResolver = yupResolver(
        yup.object().shape({
            newMessage: yup.string().required("Please enter your messsage"),
        })
    );

    const methods = useForm<FormValues>({resolver: schemaResolver});
    const {
        handleSubmit,
        register,
        control,
        formState: {errors},
        reset,
    } = methods;

    /**
     * Handle valid form submission
     */
    const handleValidMessageSubmit: SubmitHandler<FormValues> = (values) => {
        const message = values["newMessage"];
        onNewMessagesPosted(message);
        reset();
    };

    return (
        <>
            <form
                name="chat-form"
                id="chat-form"
                onSubmit={handleSubmit(handleValidMessageSubmit)}
            >
                <Row>
                    <Col>
                        <FormInput
                            type="text"
                            name="newMessage"
                            className="form-control chat-input"
                            placeholder="Enter your text"
                            register={register}
                            key="newMessage"
                            errors={errors}
                            control={control}
                            disabled={disable}
                        />
                    </Col>
                    <Col className="col-auto">
                        <button
                            type="submit"
                            className="btn btn-danger chat-send waves-effect waves-light"
                        >
                            Send
                        </button>
                    </Col>
                </Row>
            </form>
        </>
    );
};

/**
 * ChatList
 */


interface ChatListProps {
    className?: string;
    episodeDetail?: any;
    characters?: any[];
    setError?: any;
    model?: string;

}

/**
 * Renders the ChatList
 */
const ChatList = (props: ChatListProps) => {
    const [messages, setMessages] = useState<Array<MessageItem>>([]);
    const [openEvaluation, setOpenEvaluation] = useState<boolean>(false);
    const [evaluation, setEvaluation] = useState<EvaluationModel2>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {episodeDetail, characters, model, setError} = props;
    const api = useMemo(() => new APICore(), []);
    // useEffect(() => {
    //     const ai_character = characters && characters.filter(c => c.type === 'ai_character');
    //     const ids = ai_character && ai_character.length > 0 && ai_character.map((c: any) => c.id);
    //     if (!episodeDetail) return;
    //     if (ids && ids.includes(episodeDetail.openLineOwner)) {
    //         setMessages([
    //             {
    //                 id: 1,
    //                 userName: episodeDetail.name,
    //                 role: 'assistant',
    //                 content: episodeDetail.openLine
    //             }
    //         ])
    //     }
    //     else {
    //         setMessages([])
    //     }
    // }, [episodeDetail, model, characters]);

    /**
     * Handle new message posted
     */
    const handleNewMessagePosted = async (message: string) => {
        try {
            setIsLoading(true);
            setMessages(messages.concat({
                    id: messages.length + 1,
                    userName: api.getLoggedInUser().userName,
                    role: 'user',
                    content: message
                })
            );
            const chatRequest = {
                model: model,
                summary: '',
                messages:
                    messages.map((message) => {
                        return {
                            role: message.role,
                            content: message.content
                        }
                    }).concat({
                        role: 'user',
                        content: message

                    })

            }
            const response = await continuations(episodeDetail.id, chatRequest as ChatRequest);
            setMessages(messages.concat({
                    id: messages.length + 1,
                    userName: api.getLoggedInUser().userName,
                    role: 'user',
                    content: message
                }, {
                    id: messages.length + 2,
                    userName: episodeDetail.name,
                    role: 'assistant',
                    content: response.data.message.content
                })
            );
        } catch (e) {
            setError(e as string);
        } finally {
            setIsLoading(false);
        }

    };

    const resetChat = async () => {
        try {
            setMessages([
                {
                    id: 1,
                    userName: episodeDetail.name,
                    role: 'assistant',
                    content: episodeDetail.openLine
                }
            ])
            await episodesReset(episodeDetail.storyId, episodeDetail.id);
        } catch (e) {
            setError(e as string);
        }


    }

    const evaluationChat = async () => {
        try {
            const chatRequest = {
                summary: '',
                messages:
                    messages.map((message) => {
                        return {
                            role: message.role,
                            content: message.content
                        }
                    })
            }
            const res = await episodesEvaluation(episodeDetail.storyId, episodeDetail.id, chatRequest);
            setEvaluation(res.data);
            setOpenEvaluation(true);
        } catch (e) {
            setError(e as string);
        }

    }

    const deleteLastChatUser = async () => {
        if (messages[messages.length - 1].role === 'assistant') {
            setMessages(messages.slice(0, messages.length - 2));
        }
    }

    const endOfMessagesRef = React.useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>
            <Card>
                <Card.Body style={{padding: "0 24px"}}>
                    <Dropdown className="float-end" align="end">
                        <Dropdown.Toggle
                            as="a"
                            className="cursor-pointer card-drop p-0 shadow-none"
                        >
                            <i className="mdi mdi-dots-vertical"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={resetChat}>Reset</Dropdown.Item>
                            <Dropdown.Item onClick={evaluationChat}>Evaluation</Dropdown.Item>
                            <Dropdown.Item onClick={deleteLastChatUser}>Delete last chat User</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/*<h4 className="header-title mb-3">{episodeDetail.name}</h4>*/}

                    <div className="chat-conversation">
                        {/* chat messages */}
                        <SimpleBar style={{height: "50vh", width: "80%"}}>
                            <ul className={classNames("conversation-list", props.className)}>
                                {(messages || []).map((message, i) => {
                                    return (
                                        <ChatItem
                                            key={i}
                                            placement={
                                                message.userName === episodeDetail.name ? "right" : "left"
                                            }
                                        >
                                            <ChatItemText
                                                userName={""}
                                                text={message.content}
                                            />
                                        </ChatItem>
                                    );
                                })}
                                {isLoading && <ChatItem
                                    placement={"right"}
                                >
                                    <ChatItemText
                                        userName={""}
                                        text={"....."}
                                    />
                                </ChatItem>}
                                <div ref={endOfMessagesRef}/>
                            </ul>
                        </SimpleBar>

                        {/* chat form */}
                        <ChatForm onNewMessagesPosted={handleNewMessagePosted} disable={isLoading}/>
                    </div>
                </Card.Body>
            </Card>
            <Modal
                title="Evaluation"
                visible={openEvaluation}
                onCancel={() => setOpenEvaluation(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setOpenEvaluation(false)}>
                        Oke
                    </Button>,
                ]}
            >
                <p>Score</p>
                <ul>
                    <li>Chats: {evaluation?.evaluation.score.chats}</li>
                    <li>Goal Accomplishment: {evaluation?.evaluation.score.goalAccomplishment}</li>
                    <li>Qualitative: {evaluation?.evaluation.score.qualitative}</li>
                </ul>
                <p>Goal archived: {evaluation?.evaluation?.goalArchived ? 'true' : 'false'}</p>
                <p>Displayed Explanation: {evaluation?.evaluation.explanation}</p>
                <p>Full Explanation: {evaluation?.evaluation.fullExplanation}</p>
            </Modal>

        </>
    );
};

export default ChatList;
