import { Button as AntdButton, Col as AntdCol, Row as AntdRow, Form, Input, Popconfirm, Select, Switch, Table, Tooltip, notification } from "antd";
import { Option } from "antd/es/mentions";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "ts-react-feather-icons";
import CustomPagination from "../../components/CustomPagination";
import PageTitle from "../../components/PageTitle";
import StoryDrawer from "../../components/Story/StoryDrawer";
import { ActionType } from "../../constants/actionType";
import { UserRole } from "../../constants/userRole";
import { deleteStory, getStoryDetail, searchStory, updateStoryActivation } from "../../helpers/api/story";
import { useUser } from "../../hooks";
import User from "../../model/User";

interface StoryModel {
  key: React.Key;
  id: string;
  name: string;
  description: string;
  developmentDirection: string;
  defaultImageUrl: string;
  status: string;
  stage: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: Date;
  modifiedAt: Date;
}

const Story = () => {

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [stories, setStories] = useState<any[]>([]);
  const [storyDetails, setStoryDetails] = useState<any>(null);
  const [actionType, setActionType] = useState<string>("");
  const [storyDrawerVisibility, setStoryDrawerVisibility] = useState<boolean>(false);

  const currentLoggedInUser = useUser().user as User || null;
  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const formValueObj = Form.useWatch([], form);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const showError = (errorMessage: string) => {
    api.error({
      message: `Error notification`,
      description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
      placement: 'topRight'
    });
  }

  const columns: ColumnsType<StoryModel> = [
    {
      title: "No.",
      dataIndex: "noNumber"
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record, index) => {
        return (
          <Link to={`/stories/${record.id}`} className="text-primary">
            {record.defaultImageUrl && <img src={record.defaultImageUrl} className="image rounded" style={{ maxWidth: "35px" }} />}
            {" "}
            {record.name}
          </Link>
        )
      },
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
      }
    },
    {
      title: "Modified by",
      dataIndex: "modifiedBy",
    },
    {
      title: "Published?",
      dataIndex: "activated",
      render: (text, record, index) => {
        return (
          <Popconfirm
            title={`${(record.status && record.status === "active") ? "Deactivate" : "Activate"} story`}
            description={`Are you sure to ${(record.status && record.status === "active") ? "deactivate" : "activate"} this story?`}
            onConfirm={() => changeActivation(record.id, record.status)}
            okText="OK"
            cancelText="Cancel"
          >
            <Switch checked={record.status === "active"}
              disabled={
                (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                  && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
              }
            />
          </Popconfirm>
        )
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record, index) => {
        return (
          (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) && (
            <>
              <Tooltip title="Update">
                <span role="button"
                  onClick={() => triggerUpdate(record.id)}
                >
                  <Icon name="edit-2" color="#0d6efd" size={16} />
                </span>
              </Tooltip>
              {" "}
              <Tooltip title="Delete">
                <Popconfirm
                  title={`Delete story`}
                  description={`Are you sure to delete this story?`}
                  onConfirm={() => triggerDeleteStory(record)}
                  okText="OK"
                  cancelText="Cancel"
                >
                  <span role="button">
                    <Icon name="trash-2" color="#dc3545" size={16} />
                  </span>
                </Popconfirm>
              </Tooltip>
            </>
          )
        );
      },
    },
  ];

  useEffect(() => {
    search();
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex === 1) {
      search();
      return;
    }
    setPageIndex(1);
  }, [pageSize]);

  const search = async () => {
    setSearchLoading(true);
    const searchObj: any = {
      pageIndex: pageIndex - 1,
      pageSize,
    };
    if (formValueObj?.name) {
      searchObj["name"] = formValueObj?.name.trim();
    }
    if (formValueObj?.status) {
      searchObj["status"] = formValueObj?.status;
    }
    if (formValueObj?.developmentDirection) {
      searchObj["developmentDirection"] = formValueObj?.developmentDirection?.trim();
    }
    try {
      const response = await searchStory(searchObj);
      if (response.status === 200) {
        const data: any[] = [];
        response.data.data.forEach((item: any, index: number) => {
          data.push({
            ...item,
            noNumber: (pageIndex - 1) * pageSize + index + 1,
          });
        });
        setStories(data);
        setTotalRecord(response.data.totalRecord);
      }
    } catch (err) {
      console.error(err);
      api.error({
        message: `Error notification`,
        description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
        placement: 'topRight'
      });
    }
    setSearchLoading(false);
  }

  const changeActivation = async (storyId: string, currentStatus: string) => {
    const story = await getStoryDetail(storyId);
    setStoryDetails(story.data.data);
    try {
      const response = await updateStoryActivation(storyId, { status: currentStatus === "active" ? "inactive" : "active" });
      if (response.status === 200) {
        api.success({
          message: `Successfull notification`,
          description: (currentStatus === "activated" ? 'Deactivate' : 'Activate') + ' story successfully!',
          placement: 'topRight'
        });
        search();
      }
    } catch (err) {
      showError(err as string);
    }
  }

  const triggerUpdate = async (storyId: string) => {
    try {
      if (!storyId || storyId?.trim()?.length === 0) {
        return;
      }
      const story = await getStoryDetail(storyId);
      setStoryDetails(story.data.data);
      setActionType(ActionType.UPDATE);
      setStoryDrawerVisibility(true);
    } catch (err) {
      console.error(err);
      showError(err as string);
    }
  }

  const triggerDeleteStory = async (storyModel: StoryModel) => {
    try {
      if (!storyModel || !storyModel?.id) {
        return;
      }
      if (storyModel.status === "active") {
        showError("Cannot delete an active story");
        return;
      }
      await deleteStory(storyModel.id);
    } catch (err) {
      console.error(err);
      showError(err as string);
    }
    search();
  }

  return (
    <>
      {contextHolder}

      <StoryDrawer
        drawerVisibility={storyDrawerVisibility}
        setdDrawerVisibility={setStoryDrawerVisibility}
        storyDetail={storyDetails}
        setStoryDetail={setStoryDetails}
        actionType={actionType}
        setActionType={setActionType}
        onSuccess={() => {
          api.success({
            message: `Successfull notification`,
            description: (actionType === ActionType.CREATE ? "Create" : "Update") + ' story successfully!',
            placement: 'topRight'
          });
          setStoryDetails(null);
          setActionType("");
          search();
        }}
        onFailure={(errorMessage: string) => {
          showError(errorMessage);
        }}
      />

      <PageTitle
        breadCrumbItems={[
          { label: "Stories", path: "/apps/stories", active: true },
        ]}
        title={"Stories"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Form layout="vertical" autoComplete='off' form={form}>
                <AntdRow gutter={16}>
                  <AntdCol span={12}>
                    <Form.Item
                      name="name"
                      label="Name"
                    >
                      <Input placeholder="Please enter collection's name" />
                    </Form.Item>
                  </AntdCol>
                  <AntdCol span={12}>
                    <Form.Item
                      name="status"
                      label="Status"
                    >
                      <Select placeholder="Please choose the status" allowClear>
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                      </Select>
                    </Form.Item>
                  </AntdCol>
                </AntdRow>
                <AntdRow gutter={16}>
                  <AntdCol span={12}>
                    <Form.Item
                      name="developmentDirection"
                      label="Development direction"
                    >
                      <Input placeholder="Please enter development direction" />
                    </Form.Item>
                  </AntdCol>
                </AntdRow>
                <AntdRow className="justify-content-center">
                  <AntdCol>
                    <AntdButton type="primary" className="me-1" htmlType="submit" loading={searchLoading}
                      onClick={() => {
                        if (pageIndex === 1) {
                          search();
                          return;
                        }
                        setPageIndex(1);
                      }}
                    >
                      Search
                    </AntdButton>
                    <AntdButton type="default" loading={searchLoading} onClick={() => {
                      form.resetFields();
                      if (pageIndex === 1) {
                        search();
                        return;
                      }
                      setPageIndex(1);
                    }}>
                      Reset
                    </AntdButton>
                  </AntdCol>
                </AntdRow>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col sm={12} className="mb-2">
                  <div className="text-sm-end">
                    <Button variant="primary" className="mb-1 py-1 me-1" onClick={() => {
                      setActionType(ActionType.CREATE);
                      setStoryDrawerVisibility(true);
                    }}>
                      <i className="mdi mdi-plus-circle me-1"></i> Create
                    </Button>
                  </div>
                </Col>
              </Row>

              <Table
                loading={searchLoading}
                rowKey={"noNumber"}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={stories}
                columns={columns}
                pagination={false}
              />

              {stories?.length !== 0 && (
                <CustomPagination
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  totalItem={totalRecord}
                  setPageSize={setPageSize}
                  setPageIndex={setPageIndex}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Story;
