import { Form, Popconfirm, Switch, Table, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Icon } from "ts-react-feather-icons";
import { ActionType } from "../../constants/actionType";
import { UserRole } from "../../constants/userRole";
import { deleteCharacter, deleteStory, getCharacterDetail, getStoryCharacters, getStoryDetail, updateCharacter, updateStoryActivation } from "../../helpers/api/story";
import { useUser } from "../../hooks";
import User from "../../model/User";
import CustomPagination from "../CustomPagination";
import CharacterDrawer from "./CharacterDrawer";
import CharacterImageDrawer from "./CharacterImageDrawer";

interface CharacterModel {
    key: React.Key;
    id: string;
    storyId: string;
    name: string;
    description: string;
    status: string;
    defaultImageUrl: string;
    createdBy: string;
    modifiedBy: string;
    createdAt: Date;
    modifiedAt: Date;
    generalInformation: string;
    type: string;
}

const CharacterTable = ({
    story
}: any) => {

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [characters, setCharacters] = useState<any[]>([]);
    const [characterDetail, setCharacterDetail] = useState<any>(null);
    const [actionType, setActionType] = useState<string>("");
    const [characterDrawerVisibility, setCharacterDrawerVisibility] = useState<boolean>(false);
    const [characterImageDrawerVisibility, setCharacterImageDrawerVisibility] = useState<boolean>(false);

    const currentLoggedInUser = useUser().user as User || null;
    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }

    const columns: ColumnsType<CharacterModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record, index) => {
                return (
                    <span>
                        {record.defaultImageUrl && <img src={record.defaultImageUrl} className="image rounded" style={{ maxWidth: "35px" }} />}
                        {"  "}
                        {record.name}
                    </span>
                )
            },
        },
        {
            title: "Type",
            dataIndex: "type",
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record, index) => {
                return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
            }
        },
        {
            title: "Modified by",
            dataIndex: "modifiedBy",
        },
        {
            title: "Published?",
            dataIndex: "activated",
            render: (text, record, index) => {
                return (
                    <Popconfirm
                        title={`${(record.status && record.status === "active") ? "Deactivate" : "Activate"} character`}
                        description={`Are you sure to ${(record.status && record.status === "active") ? "deactivate" : "activate"} this character?`}
                        onConfirm={() => changeActivation(record.storyId, record.id, record.status)}
                        okText="OK"
                        cancelText="Cancel"
                    >
                        <Switch checked={record.status === "active"}
                            disabled={
                                (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                                    && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
                            }
                        />
                    </Popconfirm>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record, index) => {
                return (
                    (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) && (
                        <>
                            <Tooltip title="Image management">
                                <span role="button"
                                    onClick={() => triggerShowImage(record.id)}
                                >
                                    <Icon name="image" color="#fd7e14" size={16} />
                                </span>
                            </Tooltip>
                            <Tooltip title="Update">
                                <span role="button"
                                    onClick={() => triggerUpdate(record.storyId, record.id)}
                                    className="d-inline-block ms-1"
                                >
                                    <Icon name="edit-2" color="#0d6efd" size={16} />
                                </span>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Popconfirm
                                    title={`Delete character`}
                                    description={`Are you sure to delete this character?`}
                                    onConfirm={() => triggerDeleteCharacter(record)}
                                    okText="OK"
                                    cancelText="Cancel"
                                >
                                    <span role="button" className="d-inline-block ms-1">
                                        <Icon name="trash-2" color="#dc3545" size={16} />
                                    </span>
                                </Popconfirm>
                            </Tooltip>
                        </>
                    )
                );
            },
        },
    ];

    useEffect(() => {
        if (!story) {
            return;
        }
        search();
    }, [pageIndex]);

    useEffect(() => {
        if (!story) {
            return;
        }
        if (pageIndex === 1) {
            search();
            return;
        }
        setPageIndex(1);
    }, [pageSize]);

    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
        };
        try {
            const response = await getStoryCharacters(story.id, searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setCharacters(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }

    const changeActivation = async (storyId: string, characterId: string, currentStatus: string) => {
        const character = await getCharacterDetail(storyId, characterId);
        if (currentStatus === "inactive" && character.data.data.type === "user_character") {
            const char = character.data.data;
            const count = characters.filter(c => c.status === "active" && c.id !== char.id && c.type === "user_character").length;
            if (count >= 1) {
                showError("There must be only 1 active user character at a time");
                return;
            } else {
                if (char.name !== "{{user.name}}") {
                    showError("User character must be '{{user.name}}', please update the name following this rule and activate character!");
                    return;
                }
            }
        }
        const response = await updateCharacter(storyId, characterId, { ...character.data.data, status: currentStatus === "active" ? "inactive" : "active" });
        if (response.status === 200) {
            api.success({
                message: `Successfull notification`,
                description: (currentStatus === "activated" ? 'Deactivate' : 'Activate') + ' character successfully!',
                placement: 'topRight'
            });
            search();
        }
    }

    const triggerUpdate = async (storyId: string, characterId: string) => {
        try {
            if (!characterId || characterId?.trim()?.length === 0) {
                return;
            }
            const char = await getCharacterDetail(storyId, characterId);
            setCharacterDetail(char.data.data);
            setActionType(ActionType.UPDATE);
            setCharacterDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    const triggerDeleteCharacter = async (char: CharacterModel) => {
        try {
            if (!char || !char?.id) {
                return;
            }
            if (char.status === "active") {
                showError("Cannot delete active character");
                return;
            }
            await deleteCharacter(story.id, char.id);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
        search();
    }

    const triggerShowImage = async (characterId: string) => {
        try {
            if (!characterId || characterId?.trim()?.length === 0) {
                return;
            }
            const characterTmp = await getCharacterDetail(story.id, characterId);
            setCharacterDetail(characterTmp.data.data);
            setCharacterImageDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    return (
        <>
            {contextHolder}

            <CharacterDrawer
                story={story}
                drawerVisibility={characterDrawerVisibility}
                setdDrawerVisibility={setCharacterDrawerVisibility}
                characterDetail={characterDetail}
                setCharacterDetail={setCharacterDetail}
                actionType={actionType}
                setActionType={setActionType}
                characters={characters}
                onSuccess={() => {
                    api.success({
                        message: `Successfull notification`,
                        description: (actionType === ActionType.CREATE ? "Create" : "Update") + ' character successfully!',
                        placement: 'topRight'
                    });
                    setCharacterDetail(null);
                    setActionType("");
                    search();
                }}
                onFailure={(errorMessage: string) => {
                    showError(errorMessage);
                }}
            />

            <CharacterImageDrawer
                storyId={story.id}
                drawerVisibility={characterImageDrawerVisibility}
                setdDrawerVisibility={setCharacterImageDrawerVisibility}
                characterDetail={characterDetail}
                setCharacterDetail={setCharacterDetail}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6} className="mb-2">
                                    <span className="fw-bold lead text-success">CHARACTERS</span>
                                </Col>
                                <Col sm={6} className="mb-2">
                                    <div className="text-sm-end">
                                        <Button variant="success" className="mb-1 py-1 me-1" onClick={() => {
                                            search();
                                        }}>
                                            <i className="mdi mdi-refresh me-1"></i> Refresh
                                        </Button>
                                        <Button variant="primary" className="mb-1 py-1 me-1" onClick={() => {
                                            setActionType(ActionType.CREATE);
                                            setCharacterDrawerVisibility(true);
                                        }}>
                                            <i className="mdi mdi-plus-circle me-1"></i> Create
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Table
                                loading={searchLoading}
                                rowKey={"noNumber"}
                                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                                dataSource={characters}
                                columns={columns}
                                pagination={false}
                            />

                            {characters?.length !== 0 && (
                                <CustomPagination
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    totalItem={totalRecord}
                                    setPageSize={setPageSize}
                                    setPageIndex={setPageIndex}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default CharacterTable;
