import { Form, Popconfirm, Switch, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Icon } from "ts-react-feather-icons";
import CharacterTable from "../../components/Character/CharacterTable";
import EpisodeTable from "../../components/Episode/EpisodeTable";
import PageTitle from "../../components/PageTitle";
import StoryDrawer from "../../components/Story/StoryDrawer";
import { ActionType } from "../../constants/actionType";
import { UserRole } from "../../constants/userRole";
import { getStoryDetail, searchStory, updateStoryActivation } from "../../helpers/api/story";
import { useUser } from "../../hooks";
import User from "../../model/User";

interface StoryModel {
  key: React.Key;
  id: string;
  name: string;
  description: string;
  developmentDirection: string;
  defaultImageUrl: string;
  status: string;
  stage: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: Date;
  modifiedAt: Date;
}

const StoryDetail = () => {

  const { storyId } = useParams();

  const [story, setStory] = useState<any>(null);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [stories, setStories] = useState<any[]>([]);
  const [storyDetails, setStoryDetails] = useState<any>(null);
  const [actionType, setActionType] = useState<string>("");
  const [storyDrawerVisibility, setStoryDrawerVisibility] = useState<boolean>(false);

  const currentLoggedInUser = useUser().user as User || null;
  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const formValueObj = Form.useWatch([], form);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const showError = (errorMessage: string) => {
    api.error({
      message: `Error notification`,
      description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
      placement: 'topRight'
    });
  }

  useEffect(() => {
    if (!storyId) {
      return;
    }
    getStoryDetailById();
  }, [storyId]);

  const getStoryDetailById = async () => {
    try {
      const res = await getStoryDetail(storyId as string);
      setStory(res.data.data);
    } catch (err) {
      console.error(err);
      showError(err as string);
    }
  }

  const columns: ColumnsType<StoryModel> = [
    {
      title: "No.",
      dataIndex: "noNumber"
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record, index) => {
        return (
          <span>
            {record.defaultImageUrl && <img src={record.defaultImageUrl} className="image rounded" style={{ maxWidth: "35px" }} />}
            {" "}
            {record.name}
          </span>
        )
      },
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
      }
    },
    {
      title: "Modified by",
      dataIndex: "modifiedBy",
    },
    {
      title: "Published?",
      dataIndex: "activated",
      render: (text, record, index) => {
        return (
          <Popconfirm
            title={`${(record.status && record.status === "active") ? "Deactivate" : "Activate"} story`}
            description={`Are you sure to ${(record.status && record.status === "active") ? "deactivate" : "activate"} this story?`}
            onConfirm={() => changeActivation(record.id, record.status)}
            okText="OK"
            cancelText="Cancel"
          >
            <Switch checked={record.status === "active"}
              disabled={
                (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                  && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
              }
            />
          </Popconfirm>
        )
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record, index) => {
        return (
          (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) && (
            <Tooltip title="Update">
              <span role="button"
                onClick={() => triggerUpdate(record.id)}
              >
                <Icon name="edit-2" color="#0d6efd" size={16} />
              </span>
            </Tooltip>
          )
        );
      },
    },
  ];

  useEffect(() => {
    search();
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex === 1) {
      search();
      return;
    }
    setPageIndex(1);
  }, [pageSize]);

  const search = async () => {
    setSearchLoading(true);
    const searchObj: any = {
      pageIndex: pageIndex - 1,
      pageSize,
    };
    if (formValueObj?.name) {
      searchObj["name"] = formValueObj?.name.trim();
    }
    if (formValueObj?.gender) {
      searchObj["status"] = formValueObj?.status;
    }
    if (formValueObj?.stage) {
      searchObj["developmentDirection"] = formValueObj?.developmentDirection?.trim();
    }
    try {
      const response = await searchStory(searchObj);
      if (response.status === 200) {
        const data: any[] = [];
        response.data.data.forEach((item: any, index: number) => {
          data.push({
            ...item,
            noNumber: (pageIndex - 1) * pageSize + index + 1,
          });
        });
        setStories(data);
        setTotalRecord(response.data.totalRecord);
      }
    } catch (err) {
      console.error(err);
      api.error({
        message: `Error notification`,
        description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
        placement: 'topRight'
      });
    }
    setSearchLoading(false);
  }

  const changeActivation = async (storyId: string, currentStatus: string) => {
    const story = await getStoryDetail(storyId);
    setStoryDetails(story.data.data);
    const response = await updateStoryActivation(storyId, { status: currentStatus === "active" ? "inactive" : "active" });
    if (response.status === 200) {
      api.success({
        message: `Successfull notification`,
        description: (currentStatus === "activated" ? 'Deactivate' : 'Activate') + ' story successfully!',
        placement: 'topRight'
      });
      search();
    }
  }

  const triggerUpdate = async (storyId: string) => {
    try {
      if (!storyId || storyId?.trim()?.length === 0) {
        return;
      }
      const story = await getStoryDetail(storyId);
      setStoryDetails(story.data.data);
      setActionType(ActionType.UPDATE);
      setStoryDrawerVisibility(true);
    } catch (err) {
      console.error(err);
      showError(err as string);
    }
  }

  return (
    story && (<>
      {contextHolder}

      <StoryDrawer
        drawerVisibility={storyDrawerVisibility}
        setdDrawerVisibility={setStoryDrawerVisibility}
        storyDetail={storyDetails}
        setStoryDetail={setStoryDetails}
        actionType={actionType}
        setActionType={setActionType}
        onSuccess={() => {
          api.success({
            message: `Successfull notification`,
            description: 'Create character successfully!',
            placement: 'topRight'
          });
          setStoryDetails(null);
          setActionType("");
          search();
        }}
        onFailure={(errorMessage: string) => {
          showError(errorMessage);
        }}
      />

      <PageTitle
        breadCrumbItems={[
          { label: "Stories", path: "/stories", active: false },
          { label: "Stories detail", path: "/stories/" + storyId, active: true },
        ]}
        title={<span><span className="text-danger">{story.name}</span> story</span>}
      />

      <Row>
        <Col>
          <CharacterTable story={story} />
          <EpisodeTable story={story} />
        </Col>
      </Row>
    </>)
  );
};

export default StoryDetail;
