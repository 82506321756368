import { Button, Col, Divider, Drawer, Form, Input, InputNumber, Modal, Row, Select, Upload, UploadFile, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Option } from 'antd/es/mentions';
import { RcFile, UploadProps } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { Icon } from 'ts-react-feather-icons';
import { ActionType } from '../../constants/actionType';
import { createStory, updateStory } from '../../helpers/api/story';
import './StoryDrawer.scss';

const storyDrawerValidation = {
    name: [
        { required: true, message: 'Name is required' },
        { max: 100, message: 'Name must be less than 100 characters' },
    ],
    developmentDirection: [
        { required: true, message: 'Development direction is required' },
        { max: 5000, message: 'Development direction must be less than 5000 characters' },
    ],
    description: [
        { max: 5000, message: 'Development direction must be less than 5000 characters' },
    ],
    status: [
        { required: true, message: 'Status is required' },
    ],
    rarity: [
        { required: true, message: 'Rarity is required' },
    ],
    gems: [
        { required: true, message: 'Gems is required' },
    ]
}

const StoryDrawer = ({
    drawerVisibility,
    setdDrawerVisibility,
    storyDetail,
    setStoryDetail,
    actionType,
    setActionType,
    onSuccess,
    onFailure
}: any) => {

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);

    const [submittables, setSubmittable] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

    const [storyStatus, setStoryStatus] = useState<string>("inactive");
    const [storyRarity, setStoryRarity] = useState<number>(1);
    const [storyGems, setStoryGems] = useState<number>(100);

    const [api, contextHolder] = notification.useNotification();

    // for image
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [images, setImages] = useState<UploadFile[]>([]);

    // images
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setImages(newFileList);

    const uploadButton = (
        <div>
            {/* <PlusOutlined rev={1} /> */}
            <Icon name="plus" size={16} />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(
                () => { setSubmittable(true) },
                () => { setSubmittable(false) },
            );
    }, [formValueObj]);

    useEffect(() => {
        if (!storyRarity) {
            return;
        }
        switch (storyRarity) {
            case 1:
                setStoryGems(100);
                form.setFieldValue("gems", 100);
                break;
            case 2:
                setStoryGems(200);
                form.setFieldValue("gems", 200);
                break;
            case 3:
                setStoryGems(300);
                form.setFieldValue("gems", 300);
                break;
            case 4:
                setStoryGems(400);
                form.setFieldValue("gems", 400);
                break;
            case 5:
                setStoryGems(500);
                form.setFieldValue("gems", 500);
                break;
        }
    }, [storyRarity]);

    useEffect(() => {
        if (actionType === ActionType.CREATE) {
            form.resetFields();
            setSubmittable(false);
            form.setFieldValue("status", "inactive");
            setStoryRarity(1);
            form.setFieldValue("rarity", 1);
            form.setFieldValue("gems", 100);
        }
    }, [actionType]);

    useEffect(() => {
        if (!storyDetail) {
            form.setFieldValue("status", "inactive");
            setStoryRarity(1);
            form.setFieldValue("rarity", 1);
            form.setFieldValue("gems", 100);
            return;
        }
        if (storyDetail?.defaultImageUrl && storyDetail?.defaultImageUrl?.trim().length > 0) {
            const urlTemp = storyDetail?.defaultImageUrl.split("/");
            setImages([{
                uid: "1",
                name: urlTemp[urlTemp.length - 1],
                status: 'done',
                url: storyDetail?.defaultImageUrl
            }]);
        }
        form.setFieldsValue({ ...storyDetail });
        setStoryStatus(storyDetail.status);
        setStoryRarity(storyDetail.rarity);
    }, [storyDetail]);

    const onClose = () => {
        form.resetFields();
        setStoryDetail(null);
        setImages([]);
        form.setFieldValue("status", "inactive");
        setStoryRarity(1);
        form.setFieldValue("rarity", 1);
        form.setFieldValue("gems", 100);
        setSubmittable(false);
        setdDrawerVisibility(false);
        setActionType("");
    }

    const saveStory = async () => {
        try {
            form.validateFields();

            setLoadingBtn(true);
            let response;
            const requestModel: any = {
                name: formValueObj.name?.trim(),
                status: storyStatus,
                developmentDirection: formValueObj.developmentDirection?.trim(),
                description: formValueObj.description?.trim(),
                seedId: formValueObj.seedId,
                rarity: storyRarity,
                gems: formValueObj.gems
            }
            if (actionType === ActionType.CREATE) {
                response = await createStory({
                    data: JSON.stringify(requestModel),
                    image: images.length > 0 ? images?.[0]?.originFileObj : null
                });
            } else {
                const requestBody: any = {};
                if (images?.[0] && images?.[0].url === storyDetail.defaultImageUrl) {
                    requestModel["defaultImageUrl"] = storyDetail.defaultImageUrl;
                } else {
                    requestBody["image"] = images?.[0]?.originFileObj;
                }
                requestBody["data"] = JSON.stringify(requestModel);
                response = await updateStory(storyDetail.id, requestBody);
            }

            setLoadingBtn(false);
            if (response?.status === 200) {
                onSuccess();
                onClose();
                return;
            }
        } catch (err) {
            console.log(err);
            onFailure(err);
        }
        setLoadingBtn(false);
    }

    return (
        <Drawer
            title={(actionType === ActionType.UPDATE ? "Update" : "Create") + " a story"}
            className='character-drawer'
            width={750}
            onClose={() => { setdDrawerVisibility(false) }}
            open={drawerVisibility}
            closeIcon={false}
            footer={
                <div className='d-flex justify-content-center'>
                    <Button onClick={saveStory} type="primary" className='me-3' disabled={!submittables} loading={loadingBtn}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            }
        >
            {contextHolder}
            <Form layout="vertical" autoComplete='off' form={form}>
                <Divider orientation="left" className='text-danger'>01. STORY IMAGE</Divider>
                <Row gutter={16} className='d-flex justify-content-center'>
                    <Form.Item
                        name="image"
                        label="Image"
                    >
                        <Upload
                            listType="picture-card"
                            fileList={images}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            multiple={false}
                            accept="image/png, image/jpeg, image/jpg"
                        >
                            {images.length >= 1 ? null : uploadButton}
                        </Upload>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </Form.Item>
                </Row>
                <Row gutter={16} className='mb-3'>
                    <Col span={24}>
                        <Form.Item
                            name="seedId"
                            label="Seed id"
                        >
                            <InputNumber placeholder="Please enter seed id" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" className='text-danger'>02. STORY COMMON INFORMATION</Divider>
                <Row gutter={16} className='mb-3'>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={storyDrawerValidation.name}
                        >
                            <Input placeholder="Please enter name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="status"
                            label="Status"
                            rules={storyDrawerValidation.status}
                        >
                            <Select
                                placeholder="Please select a status"
                                value={storyStatus}
                                onChange={(e) => setStoryStatus(e)}
                                disabled={actionType === ActionType.CREATE}
                            >
                                <Option value="active">Active</Option>
                                <Option value="inactive">Inactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className='mb-3'>
                    <Col span={12}>
                        <Form.Item
                            name="rarity"
                            label="Rarity"
                            rules={storyDrawerValidation.rarity}
                        >
                            <Select placeholder="Please select a rarity" defaultValue={1} value={storyRarity} onChange={(e) => setStoryRarity(Number(e))}>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="gems"
                            label="Gems"
                            rules={storyDrawerValidation.gems}
                        >
                            <InputNumber min={0} className='w-100' disabled={true} value={storyGems} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="developmentDirection"
                            label="Development direction"
                            rules={storyDrawerValidation.developmentDirection}
                        >
                            <TextArea rows={4} placeholder="Enter development direction" maxLength={5000} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={storyDrawerValidation.description}
                        >
                            <TextArea rows={4} placeholder="Enter description" maxLength={5000} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )

}

export default StoryDrawer;
